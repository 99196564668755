@media (max-width: 68rem) {
  .navbar-menu.is-active {
    overflow: auto;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 80%;
    height: 100%;
    padding: 1rem;

    .navbar-end {
      display: flex;
      flex-direction: column;
      margin-top: 2.5rem;

      .navbar-item {
        border-top: none;

        &:hover {
          border-top: none;
          text-decoration: underline;
        }

        &.overline {
          border-top: none;
          text-decoration: underline;
        }
      }
    }

    .language-selection-wrapper {
      display: none;
    }
    .language-selection-wrapper--mobile select{
      display: block;
    }
  }
}

#language-select-label {
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  margin: -1px;
  clip: rect(0 0 0 0);
  border: 0;
}

.language-selection-wrapper--mobile select {
  display: none;
  font-family: "Poppins", Helvetica, sans-serif;
  min-width: 0;
  background-color: transparent;
  outline: none;
  width: 120px;
  border: none;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;

  &:hover {
    text-decoration: underline;
  }
}

nav.navbar {
  background-color: transparent;
  transition: all 0.6s ease-out;

  @media (min-width: $tablet) {
    padding: 0 2rem;
  }

  .navbar-end .navbar-item {
    border-top: 2px solid transparent;
    box-sizing: border-box;

    &:hover {
      border-top: 2px solid $pp-cta;
      text-decoration: none;
    }

    &.overline {
      border-top: 2px solid $pp-cta;
    }
  }

  .navbar-item,
  .navbar-switch,
  .navbar-link,
  .navbar-burger {
    color: #363636;
    font-size: 1.125rem;
    font-weight: 400;
  }

  .navbar-switch {
    font-size: 1.5rem;
    cursor: pointer;
    border-color: transparent;
    background-color: transparent;
  }

  .navbar-burger {
    position: absolute;
    right: 0;
    height: 4.25rem;
    transition: height 400ms ease;
  }

  &.scrolled {
    opacity: 0.97;
    background-color: white;
    box-shadow: 0 0 45px 11px rgba(0, 0, 0, 0.05);

    .navbar-item,
    .navbar-switch,
    .navbar-link,
    .navbar-burger {
      color: $black-ter;
    }

    .navbar-burger {
      height: 3.25rem;
    }

    img.logo {
      opacity: 0;
      height: 2.25rem;


      &--scrolled {
        opacity: 1;
        height: 2.25rem;
      }
    }
  }

  img.logo {
    height: 2.75rem;
    max-height: none;
    transition: height 400ms ease;
    padding: 0.5rem 0.5rem 0.25rem;

    &--scrolled {
      position: absolute;
      opacity: 0;
    }

    @media (min-width: $tablet) {
      height: 3rem;
      padding: 0.5rem 0 0.25rem;
    }
  }


  .navbar-dropdown {
    margin-right: 0.5rem;
    right: 0;
    left: inherit;

    .menu-select-flag {
      margin-right: 0.5rem;
    }
  }

  .navbar-link:not(.is-arrowless)::after {
    border-color: currentColor;
  }

  &--white {
    &:not(.scrolled) {
      .navbar-item,
      .navbar-link,
      .navbar-switch,
      .navbar-burger {
        color: white;

        &:hover {
          color: whitesmoke;
        }
      }

      .navbar-end .navbar-item {
        &:hover {
          border-top: 2px solid white;
        }

        &.overline {
          border-top: 2px solid white;
        }
      }

      .navbar-dropdown {
        .navbar-item,
        .navbar-switch,
        .navbar-link,
        .navbar-burger {
          color: #363636;

          &:hover {
            color: black;
          }
        }
      }
    }
  }
}
