$primary: #F4C3C2;
$link: #0c1e40;
$info: #F5F5F5;

$pp-midnight: #0c1e40;
$pp-green: #4ECDC4;
$pp-pink: #F4C3C2;
$pp-cta : #6265F0;
$pp-sky: #4d8af0;
$pp-violet : #ae85ca;
$pp-cherry : #de2b95;

$navbar-breakpoint: 1088px !default;
$navbar-item-hover-background-color: transparent;
$dropdown-item-active-background-color: $pp-cta;

$input-focus-box-shadow-size: 0;

$dimensions: 16 24 32 48 64 96 128 192 256 !default