/* purgecss start ignore */
[data-aos][data-aos][data-aos-duration="400"],body[data-aos-duration="400"] [data-aos] {
    transition-duration: .4s
}

[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity,transform
}

[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: translateZ(0)
}

[data-aos=fade-right] {
    transform: translate3d(-100px,0,0)
}

[data-aos=fade-left] {
    transform: translate3d(100px,0,0)
}
/* purgecss end ignore */