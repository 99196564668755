@charset "utf-8";
@import "variables";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/form/shared.sass";
@import "../../../node_modules/bulma/sass/form/input-textarea.sass";
@import "../../../node_modules/bulma/sass/elements/button.sass";
@import "../../../node_modules/bulma/sass/elements/container.sass";
@import "../../../node_modules/bulma/sass/elements/icon.sass";
@import "../../../node_modules/bulma/sass/elements/image.sass";
@import "../../../node_modules/bulma/sass/elements/title.sass";
@import "../../../node_modules/bulma/sass/components/navbar.sass";
@import "../../../node_modules/bulma/sass/layout/hero.sass";
@import "../../../node_modules/bulma/sass/components/dropdown.sass";
@import "aos";
@import "navbar";

html {
  height: 100%;
}

body {
  font-family: "Poppins", Helvetica, sans-serif;
  background-color: #ffffff;
  color: #333333;
  height: 100%;
  flex-direction: column;
  display: flex;
}

main {
  flex: 1 0 auto;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: $tablet) {
  h2:not(.home__subtitle) {
    margin-bottom: 5.5rem;
  }
}

.container {
  margin: 0 1.25rem 2rem;

  @media (min-width: $tablet) {
    margin: 0 2.75rem;
  }

  @media (min-width: $desktop) {
    margin: auto;
    margin-bottom: 4rem;
  }
}

.title {
  margin-top: 4rem;
}

.subtitle {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}
.button {
  border-radius: 1.25rem;
  padding-left: 1.25em;
  padding-right: 1.25em;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  transition: all 300ms ease-out;
  color: #363636;

  &.is-pp-cta {
    background-color: $pp-cta;
    border-color: transparent;
    color: white;
  }
  &.is-pp-cta:hover {
    background-color: darken($pp-cta, 3%);
  }
  &:hover {
    transform: scale(1.05);
  }
  @media (min-width: $tablet) {
    margin-top: 0;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }
}

a.button {
  height: 42px;
}

button.button {
  height: inherit;
  padding: 0.5rem 1.25rem;

  &.is-primary,
  &.is-primary:hover {
    color: white;
  }
}

.control {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

/* LANG */
.has-icons-left {
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: left;
}

.dropdown-trigger {
  height: 100%;
  display: flex;
  align-items: stretch;
}

.dropdown-item.is-active i:before {
  transform: rotate(-90deg);
}

.dropdown-item {
  position: relative;
  padding-left: 1.5rem;

  i {
    position: absolute;
    left: 0;

    &:before {
      transform: rotate(-90deg);
    }
  }
}

/* HOME */
.home {
  height: calc(100vh - 3.25rem);
  background: url("/images/illustrations/paperplanes.svg") 1rem 5rem no-repeat;
  background-size: 90%;
  background-color: transparent;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 2rem;

  &__title {
    background-color: white;
    font-size: 1.75rem;
    font-weight: bold;
    margin: 3.25rem 0 0;
  }

  &__subtitle {
    background-color: white;
    font-size: 1.5rem;
    padding: 1rem 0;
  }

  &__icon {
    width: 100%;
    text-align: center;
    text-decoration: none;
    color: inherit;
    animation: scroll-animation 1s ease-in-out infinite;
    text-shadow: 0 0 1px #7a7a7a;

    &:hover {
      color: $pp-green;
    }
  }

  &__ctas {
    background: white;
    padding: 1rem 0;
    z-index: 1;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: $tablet - 1px) {
    background-size: auto;
    font-size: 2rem;
    background: url("/images/illustrations/paperplanes.svg") 100% 50% no-repeat;
    background-size: 50%;
    justify-content: center;

    &__title {
      width: 49%;
      font-size: 3rem;
      line-height: 1.375;
      margin: 0;
    }

    &__subtitle {
      width: 49%;
      font-size: 2rem;
    }

    &__ctas {
      max-width: 16rem;

      .button.is-outlined {
        margin-top: 0;
      }
    }
    &__icon {
      bottom: 0;
      position: absolute;
    }
  }

  @media (min-width: $desktop) {
    &__ctas {
      max-width: none;
    }
  }
}

@keyframes scroll-animation {
  0% {
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

.aside {
  display: flex;
  justify-content: center;
  align-items: center;

  .aside-card {
    background-color: white;
    box-shadow: 0 0 45px 11px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    border-radius: 1.25rem;

    &__text {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.25rem;

      &--highlight {
        color: $pp-pink;
      }

      .button.is-pp-cta {
        margin-top: 1rem;
      }
    }

    &__illustration {
      flex: 2;
      width: 75%;
      max-width: 20rem;
      margin-top: 2rem;
    }
  }

  @media (min-width: $tablet) {
    background-size: contain;

    .aside-card {
      flex-direction: row;
      padding: 2rem;

      &__text {
        padding: 1rem;
        font-size: 1.75rem;
      }
    }
  }

  @media (min-width: $desktop) {
    margin: auto;
    margin: 10rem auto;
    max-width: 75%;
  }
}

/* SOLUTIONS */
.solutions__category {
  display: flex;
}

.category {
  flex-direction: column;
  overflow: hidden;
  padding: 3.5rem 0 4rem 0;
  border-bottom: 2px solid whitesmoke;
  margin:  0 0 1.5rem;

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  &__text-block p {
    margin-bottom: 2rem;
  }

  &__illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    .image {
      display: flex;
      align-items: flex-end;
    }
  }

  @media (min-width: $tablet) {
    &:nth-of-type(even) {
      text-align: right;
      flex-direction: row-reverse;
    }
    &:nth-of-type(odd) {
      text-align: left;
      flex-direction: row;
    }

    &__text-block {
      text-align: inherit;
      flex: 3;

      p {
        line-height: 1.75;
      }
    }

    &__illustration {
      flex: 2;
    }
  }
}

.separator-illustration {
  height: 300px;
  z-index: 0;
}

.business {
  display: grid;
  grid-row-gap: 2rem;
  justify-content: center;
  margin: 0 -0.5rem;

  &__card {
    transition: transform 300ms ease;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    border-radius: 0.625rem;
    padding: 0.5rem;

    article {
      align-self: stretch;
      flex-direction: column;
      display: flex;
      align-items: flex-start;
    }

    .subtitle {
      word-break: keep-all;
      margin: 0 0 1rem;
    }

    .content {
      margin-bottom: 16px;
    }

    .button {
      display: inline-flex;
      height: 40px;
      margin-top: auto;
    }

    .image {
      padding-top: 0;
      flex: 1 0 auto;
      margin-top: 2rem;
    }
  }

  @media (min-width: $tablet) {
    margin: 0 -2rem;
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 1fr;

    &__card {
      flex-wrap: nowrap;
      padding: 2rem;
      box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.1);
      transition: box-shadow 100ms ease-out;

      &:last-of-type {
        transform: translateX(calc(50% + 1rem));
      }

      &:hover {
        box-shadow: 3px 3px 10px 5px rgba(0, 0, 0, 0.1);
      }
    }

    .image {
      margin-top: 0;
    }
  }
}

/* A PROPOS */
.a-propos {
  display: flex;
  flex-direction: column-reverse;

  &__description {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__text {
    margin-top: 1rem;
  }

  &__illustration {
    display: flex;
    justify-content: center;
    flex: 2;
    padding: 2rem;
  }

  @media (min-width: $tablet) {
    flex-direction: row-reverse;
  }
}
/* Contact */
.contact {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__hidden {
    display: none;
  }

  .label {
    font-weight: 500;
  }

  .field:first-of-type {
    margin-top: 1.75rem;
  }

  &__split {
    display: flex;
    justify-content: space-between;
  }

  &__split-item {
    display: inline-block;
    width: 45%;
  }

  &__buttons {
    margin-top: 1rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  @include mobile {
    &__split {
      flex-direction: column;
    }

    &__split-item {
      width: 100%;
    }
  }
}

/* Footer */
.footer {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  width: 100%;
  background-color: $pp-midnight;
  padding-bottom: 2rem;

  &__block {
    flex: 1;
    color: white;
    padding: 2rem 1.25rem;

    .subtitle {
      margin-top: 2.5rem;
      color: $pp-green;
    }
  }

  &__link-block {
    & a {
      color: white;
      &:hover {
        color: $pp-pink;
      }
    }

    @media (max-width: $tablet) {
      li {
        height: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &__icon-block {
    display: flex;
    flex-wrap: wrap;
    font-size: 2rem;

    & > a {
      margin-top: 1rem;
      margin-right: 1rem;
    }

    & i {
      border-radius: 0.625rem;
      color: white;

      &:hover {
        background-color: white;
      }
    }
  }

  &__logo {
    height: 5rem;
  }

  &__copy {
    font-size: 0.875em;
    margin-top: 1em;
  }

  .icon-linkedin:hover:before {
    color: #0e76a8;
    background-color: white;
  }
  .icon-twitter:hover:before {
    color: #0084b4;
  }
  .icon-skype:hover:before {
    color: #0078D7;
  }
  .icon-whatsapp:hover:before {
    color: #25D366;
  }

  @media (min-width: $tablet) {
    flex-direction: row;

    &__logo {
      height: 3rem;
      margin: 1rem 0;
    }

    &__block {
      padding: 2rem;
    }
  }
}

/* Page Wrapper + Menu */
.navbar-menu.is-active {
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.backdrop {
  transition: opacity 0.5s ease;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  &.is-active {
    opacity: 0.35;
    display: block;
  }
}

.navbar-close {
  padding: 0.5rem 0.75rem;
  margin-right: 1rem;
  position: absolute;
  right: 0;
  transition: transform 300ms ease-out;

  &:hover {
    transform: rotateZ(90deg) scale(1.2);
  }

  @media (min-width: 1088px) {
    display: none;
  }
}

.sticky {
  position: sticky;
  height: 0;
  top: 0;
  z-index: 2;
}

.navbar-item {
  &:hover,
  & .overline {
    text-decoration: underline;
  }
}

.body-404 {
  display:flex;
  flex-direction: column;
  min-height: 100vh;
}

.section-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 4rem;

  img {
    margin: 2rem;
    max-height: 500px;
  }

  .back-home {
    font-size: 1.5rem;
  }
}

.hero {
  &.is-pp-green {
    background-color: $pp-green;
    background-image: linear-gradient(141deg,#28c09c,#4ecdc4 71%,#5dccd8);
  }
  &.is-pp-pink {
    background-color: $pp-pink;
    background-image: linear-gradient(141deg,#f2919f,#f4c3c2 71%,#f9ddd6);
  }
  &.is-pp-sky {
    background-color: $pp-sky;
    background-image: linear-gradient(141deg,#128ef8,#4d8af0 71%,#607ff6);
  }
  &.is-pp-cta {
    background-color: $pp-cta;
    background-image: linear-gradient(141deg,#284ff7,#6265f0 71%,#8876f6);
  }
  &.is-pp-violet {
    background-color: $pp-violet;
    background-color: linear-gradient(141deg,#8656c6,#ae85ca 71%,#c693d5);;
  }
  &.is-pp-cherry {
    background-color: $pp-cherry;
    background-image: linear-gradient(141deg,#d4264f,#de2b95 71%,#b67ddc);
  }

  .title,
  .subtitle {
    color: white;
  }
}
